<template>

  <!-- Sales By Country Table Card -->
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h6>客服公告</h6>
        </a-col>
        <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
        </a-col>
      </a-row>
    </template>
    <!-- 功能区 -->
    <a-row type="flex" justify="start" align="middle" style="margin-bottom: 25px">
      <p style="margin: 0 16px;line-height: 1.8;">{{ notice }}</p>
    </a-row>

<!--    <a-row type="flex" align="middle" style="margin-left: 23px;margin-top: 15px">-->
<!--      <a-col :span="24" :md="12">-->
<!--        <h6>如何获取兑换码？</h6>-->
<!--      </a-col>-->
<!--      <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">-->
<!--      </a-col>-->
<!--    </a-row>-->
<!--    <a-row type="flex" align="middle" style="margin-left: 23px;margin-bottom: 25px">-->
<!--      <span>24小时自助发码平台购买 →<a href="http://code.copyapes.com/" target="_blank">点击直达</a></span>-->
<!--    </a-row>-->

  </a-card>
  <!-- / Sales By Country Table Card -->


</template>

<script>

export default ({
  props: {
    notice: {
      type: String,
      default: null,
    }},
  data() {
    return {

    }
  },
  methods: {
  },
})

</script>

<style scoped>

</style>